



































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "@/config";
import PDFConstructor from "./../../pdf/PDFConstructor.vue";
import PDFUserDataHelper from "./../../pdf/PDFUserDataHelper.vue";
import CreateDisclosureForm from "./../../pdf/CreateDisclosureForm.vue";
import PDFMetrics from "./../../pdf/PDFMetrics.vue";
import BootstrapVue from "bootstrap-vue";
import _ from "lodash";
import saveAs from "save-as";

Vue.use(BootstrapVue);

const sleep = m => new Promise(r => setTimeout(r, m))

@Component({ components: { PDFConstructor, PDFUserDataHelper, PDFMetrics, CreateDisclosureForm } })
export default class PDFConstructorPage extends Vue {
  allowedFileExtensions = '.pdf';
  value  = ''
  disclosures: any = [];
  disclosuresToDownload: Array<number> = [];
  disclosure: any = {};
  selectedDisclosure: any = null;
  loanTxnId: string = null;
  loanTxnIdInput: string = null;
  userData: any = null;
  downloadingDisclosures: boolean = false;
  formId: any = null;
  allStates = [ 'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY' ];
  loanTypes = [ 'FHA', 'VA', 'Conventional' ];
  file: any = null;
  changePDFfile: boolean = false;
  formActivation: boolean = null;
  disclosureFilesToUpload: Array<any>;

  filters = {
    states: [],
    exclusiveStates: false,
    loanTypes: [],
    broker: '',
    lender: '',
    name: ''
  };

  timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  public get isPdfConstructor (){
    return this.$store.state.sessionObject?.userInfo?.entitlements?.pdfConstructor
  }
  
  async goToLoan() {
    this.loanTxnId = null;
    this.$router.push({ query: {formId: `${this.formId}`, loanTxnId: this.loanTxnIdInput }});
    this.loanTxnId = this.loanTxnIdInput;

    await this.initPDFConstructor()
  }

  async updateSelectedDisclosure() {
    try {
      if (!this.selectedDisclosure) { return; }

      this.$store.state.wemloLoader = true;

      if (this.formActivation) {
        this.selectedDisclosure.active = true;
      }

      const formData = new FormData();
      formData.append('file', this.file);
      formData.append('disclosure', JSON.stringify(this.selectedDisclosure));

      await Axios.post(BASE_API_URL + "disclosure/updateDisclosure", formData);

      this.resetFile();
      this.$store.state.wemloLoader = false;
    } catch (e) {
      this.$store.state.wemloLoader = false;
      console.log(e)
    }
    
  }

  receiveUserData(userData) {
    this.userData = userData;
  }

  onKeySelected(key) {
    this.$refs.pdfconstructor['addKeyToSelectedField'](key);
  }

  async previousForm() {
    await this.selectDisclosure(this.disclosures[this.$refs.selectDisclosure['selectedIndex'] - 2].formId);
    this.$refs.selectDisclosure['selectedIndex']--;
  }

  async nextForm() {
    await this.selectDisclosure(this.disclosures[this.$refs.selectDisclosure['selectedIndex']++].formId);
  }

  async selectDisclosure(event) {
    this.selectedDisclosure = null;

    const formId = _.get(event, 'target.value', event);

    await sleep(500);
    if(_.get(this.$route.query, 'formId' , '').toString() != formId) {
      this.$router.replace({query: {formId: `${formId}`, loanTxnId: this.loanTxnId }});
    }

    this.resetFile();
    this.selectedDisclosure = this.disclosures.find(disclosure => disclosure.formId == formId);
    this.formActivation = _.get(this, 'selectedDisclosure.active', false);
  }

  resetFile() {
    this.changePDFfile = false;
    this.file = null;
  }

  async attachFile(event) {
    this.file = _.head(_.get(event, 'target.files', []));
  }

  async resetFilters() {

    this.filters = {
      states: [],
      exclusiveStates: false,
      loanTypes: [],
      broker: '',
      lender: '',
      name: ''
    };

    await this.getDisclosures();
  }

  async getDisclosures() {

    const response = await Axios.post(
      BASE_API_URL + "disclosure/getDisclosuresConstants",
      this.filters
    );

    this.disclosures = response.data.disclosures;
  }

  async downloadDisclosures() {
    this.downloadingDisclosures = true;
    const response = await Axios.post(
      BASE_API_URL + "disclosure/downloadDefaultDisclosures",
      {
        formIds: this.disclosuresToDownload,
      },
            { responseType: "blob" }
    );
    if (response.status === 200 && response.data) {
      saveAs(response.data, `Disclosures.zip`);
    }
    this.downloadingDisclosures = false;
  }

  async initPDFConstructor() {
    await this.getDisclosures();

    this.$refs.selectDisclosure['selectedIndex'] =
      this.disclosures.findIndex(disclosure => disclosure.formId == _.get(this.$route.query, 'formId' , '').toString()) + 1;

    this.loanTxnId = _.get(this.$route.query, 'loanTxnId' , '').toString();

    if (!this.loanTxnId) {
      await this.getLoanTxnId();
    }

    const URIQuery = { loanTxnId: this.loanTxnId };
    const formId = _.get(this.$route.query, 'formId' , '').toString();

    if (formId) {
      URIQuery['formId'] = formId;
    }

    this.$router.replace({ query: URIQuery });
    this.loanTxnIdInput = this.loanTxnId;

    this.selectDisclosure(_.get(this.$route.query, 'formId' , '').toString());
  }

  async getLoanTxnId() {
    const { data: { borrowers: [{ loanTxnId }] } } = await Axios.post(
      BASE_API_URL + "superAdmin/getBorrowers",
      {
        userId: this.$userId,
        type: this.$userType,
        options: {
          skip: 0,
          limit: 1,
        },
      }
    );

    if (loanTxnId) {
      this.loanTxnId = loanTxnId;
    }
  }
  
  async mounted () {
    if (!this.isPdfConstructor){
      this.$router.push({
        path: this.$getCurrentUserTypePath("dashboard"),
      });
    }
    await this.initPDFConstructor();
  }

  async updateDisclosures(disclosure) {
    this.disclosures[this.disclosures.findIndex(form => form.formId == disclosure.formId)] = disclosure;
  }

  async validateDisclosure(disclosure, saved) {
    if(saved) {
      this.disclosure = disclosure;
      this.disclosure.validate = true;
    }
  }

  addNewModal() {
    this.$modal.show('addNew');
  }

  closeAddNewModal() {
    this.$modal.hide('addNew');
  }

  closeDownloadDisclosuresModal() {
    this.disclosuresToDownload = [];
    this.downloadingDisclosures = false;
    this.$modal.hide('downloadDisclosures');
  }

  async formCreated() {
    this.closeAddNewModal();
    this.$snotify.success('Form created successfully');

    await this.resetFilters();
  }

  async uploadDisclosureFiles(e) {
    this.$store.state.wemloLoader = true;
    try {
      const files = e.target.files;
      if (!files.length) return;
      const formData = new FormData();
      files.forEach(f => {
        formData.append("file", f);
      });

      const response = await Axios.post(
        BASE_API_URL + "disclosure/uploadDisclosureForms",
        formData
      );

      this.$store.state.wemloLoader = false;
      if (response.status === 200) {
        this.$snotify.success("Disclosures were updated successfully");
      }
    } catch (error) {
      this.$store.state.wemloLoader = false;
    }
  }

  async downloadDisclosuresCollections() {
    const { data: zip } = await Axios.post(
      BASE_API_URL + 'disclosure/downloadDisclosuresCollections',
      {},
      { responseType: 'blob' },
    );
    
    saveAs(zip, 'disclosure-collections.zip');
  }
}
