






































































































































































































import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "@/config";
import BootstrapVue from "bootstrap-vue";
import _ from "lodash";

Vue.use(BootstrapVue);


@Component({})
export default class CreateDisclosureForm extends Vue {

  allStates = [ 'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY' ];
  loanTypes = [ 'FHA', 'VA', 'Conventional' ];
  types = [ 'Broker Disclosure', 'Additional Disclosure', 'Lender Disclosure' ];
  propagationTypes = [ 'borrowerCoborrowerSignSame', 'borrowerCoborrowerSignOwn', 'eachGiftGeneratesCopyOfForm', 'brokerRefusesDisclosures' ];
  file = null;

  copyFileFromForm: boolean = false;
  copyFieldsFromForm: boolean = false;
  selectedFormId = null;
  disclosures: any = [];

  disclosure = {
    name: '',
    filename: '',
    type: '',
    loanTypes: [],
    states: [],
    brokers: [],
    lenders: [],
    apiName: '',
    propagation: null
  };

  get valid() {
    const validateName = _.isString(_.get(this.disclosure, 'name', '')) && !_.isEmpty(_.get(this.disclosure, 'name', ''));
    const validateType = _.isString(_.get(this.disclosure, 'type', '')) && !_.isEmpty(_.get(this.disclosure, 'type', ''));
    const validatePropagation = _.isString(_.get(this.disclosure, 'propagation', '')) && !_.isEmpty(_.get(this.disclosure, 'propagation', ''));
    const validateLoanTypes = !!_.get(this.disclosure, 'loanTypes', []).length;
    const file = this.copyFileFromForm ? this.selectedFormId : this.file;
    const fields = this.copyFieldsFromForm ? this.selectedFormId : true;

    return validateName && validateType && validatePropagation && validateLoanTypes && file && fields;
  }

  async createForm() {
    if (!this.valid) { return; }

    this.$store.state.wemloLoader = true;

    const formData = new FormData();

    formData.append('file', this.file);
    formData.append('disclosure', JSON.stringify(this.disclosure));
    formData.append('selectedForm', JSON.stringify({
      copyFileFromForm: this.copyFileFromForm,
      copyFieldsFromForm: this.copyFieldsFromForm,
      selectedFormId: this.selectedFormId,
    }));

    try {
      await Axios.post(BASE_API_URL + 'disclosure/createDisclosureForm', formData);

      this.$store.state.wemloLoader = false;
      this.$emit('form-created');
    } catch (error) {
      this.$store.state.wemloLoader = false;
    }
  }

  async attachFile(event) {
    this.file = _.head(_.get(event, 'target.files', []));
  }

  async getDisclosures() {
    const { data: { disclosures } } = await Axios.post(BASE_API_URL + "disclosure/getDisclosuresConstants", {});
    this.disclosures = disclosures;
  }

  async mounted() {
    this.getDisclosures();
  }
}
